import axa from '../content/axa.png';
import capgemini from '../content/capgemini.png';
import orange from '../content/orange.png';
import smile from '../content/smile.png';
import ratp from '../content/ratp.png';
import inwi from '../content/inwi.png';
import kiwi from '../content/kiwi.png';
import amde from '../content/amde.png';
import gear9 from '../content/gear9.png';
import nespresso from '../content/nespresso.png';

export const clientsData = [
  {
    id: 1,
    name: "AXA France",
    logo: "https://www.axa.fr/content/dam/axa-fr-convergence/transverse/HP/Logo%20AXA.svg",
    websitePreview: axa,
    description: "Global leader in insurance and asset management, AXA supports its clients in their daily lives. Collaboration on innovative projects in the digital insurance sector.",
    projectLink: "https://www.axa.fr"
  },
  {
    id: 2,
    name: "Nespresso Switzerland",
    logo: "https://www.nespresso.com/ecom/medias/sys_master/public/29170601951262/nespresso-logo-dark.svg",
    websitePreview: nespresso,
    description: "Global leader in premium portioned coffee. Development and optimization of e-commerce solutions to enhance the online shopping experience and customer loyalty.",
    projectLink: "https://www.nespresso.com/ch/en"
  },
  {
    id: 3,
    name: "Capgemini",
    logo: "https://www.capgemini.com/wp-content/themes/capgemini-komposite/assets/images/logo.svg",
    websitePreview: capgemini,
    description: "Global leader in consulting, digital transformation, and technology and engineering services. Partnership on major digital transformation projects.",
    projectLink: "https://www.capgemini.com"
  },
  {
    id: 4,
    name: "Orange Maroc",
    logo: "https://www.orange.ma/img/contenus/logo.png",
    websitePreview: orange,
    description: "A leading telecommunications operator in Morocco. Development of innovative digital solutions to enhance customer experience and mobile services.",
    projectLink: "https://orange.ma"
  },
  {
    id: 5,
    name: "INWI",
    logo: "https://www.hostoweb.com/img/datacenters/logo-inwi.png",
    websitePreview: inwi,
    description: "A comprehensive telecommunications operator in Morocco. Implementation of innovative digital solutions for managing telecommunication services.",
    projectLink: "https://inwi.ma"
  },
  {
    id: 8,
    name: "RATP",
    logo: "https://ratp.publispeak.com/brochure-institutionnelle-2019/uploads/297/7457c5a1129a0451cafd2c8156e91b3fcfa4cd16.png",
    websitePreview: ratp,
    description: "Autonomous Parisian Transport Authority, a key player in public transport. Development of digital solutions to improve the traveler experience.",
    projectLink: "https://www.ratp.fr"
  },
  {
    id: 6,
    name: "Gear 9",
    logo: "https://gear9.ma/sites/default/files/2024-07/Logo-g9footer.svg",
    websitePreview: gear9,
    description: "A software development company specializing in creating customized solutions and digital transformation. Expertise in web development, mobile applications, and integration of complex systems.",
    projectLink: "https://gear9.ma"
  },
  {
    id: 7,
    name: "Smile",
    logo: "https://upload.wikimedia.org/wikipedia/fr/thumb/e/ef/Logo_Smile.png/800px-Logo_Smile.png?20200724115628",
    websitePreview: smile,
    description: "European leader in open-source integration and expertise. Collaboration on large-scale projects utilizing the most innovative open-source technologies.",
    projectLink: "https://smile.eu/fr"
  },
  {
    id: 9,
    name: "Kiwi Collecte",
    logo: "https://kiwicollecte.s3.eu-north-1.amazonaws.com/kiwi_collecte_logo_010952adb2.webp",
    websitePreview: kiwi,
    description: "The first Moroccan crowdfunding platform.",
    projectLink: "https://kiwicollecte.com"
  },
  {
    id: 10,
    name: "AMDE",
    logo: "https://amde.ma/wp-content/uploads/2021/11/site-logo.png",
    websitePreview: amde,
    description: "Moroccan Association for the Development of Entrepreneurship. Creation of a digital platform to support entrepreneurship in Morocco.",
    projectLink: "https://amde.ma"
  }
];
