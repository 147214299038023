import React from 'react';
import { Box, Typography, Button, IconButton, Container, Stack } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { primaryColor } from '../content/constants';


interface HeroProps {
    name?: string;
    title?: string;
    description?: string;
}

const Hero: React.FC<HeroProps> = ({
    name = "Yassine Hjiej",
    title = "Professional software engineer",
    description = "With over 5 years of experience, I help businesses transform their ideas into applications that perform, scale, and, yes, look impressive. Ready to see your project reach new heights? Let’s make it happen."
}) => {
    const socialIcons = [
        { icon: <InstagramIcon />, name: 'Instagram', link:'https://www.instagram.com/hjiejyassine?igsh=cDZlNTR6emw3cm1w&utm_source=qr' },
        { icon: <LinkedInIcon />, name: 'LinkedIn', link:'https://www.linkedin.com/in/yassine-hjiej?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' }
    ];

    const handleItemClick = (item: string) => {
        const element = document.getElementById(item);
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      };

    return (
        <Box sx={{ backgroundColor: "#FAFAFA" }} id="Home">
            <Box
                sx={{
                    height: { xs: 'auto', md: '700px' }, // Hauteur fixe sur desktop
                    minHeight: { xs: '100vh', md: '700px' },
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background: primaryColor,
                        clipPath: 'polygon(85% 0, 100% 0, 100% 100%, 40% 100%)',
                        zIndex: 0
                    }
                }}
            >
                {/* Social Media Sidebar - Desktop */}
                <Stack
                    spacing={2}
                    sx={{
                        position: 'fixed',
                        left: { xs: 'none', lg: 32 },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        display: { xs: 'none', lg: 'flex' },
                        zIndex: 1
                    }}
                >
                    {socialIcons.map((social) => (
                        <IconButton
                            key={social.name}
                            href={social.link}
                            aria-label={social.name}
                            sx={{
                                color: '#666',
                                '&:hover': {
                                    color: primaryColor
                                }
                            }}
                        >
                            {social.icon}
                        </IconButton>
                    ))}
                </Stack>

                <Container
                    maxWidth="lg"
                    sx={{
                        height: '100%',
                        py: { xs: 4, md: 0 },
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: { xs: 4, lg: 8 },
                            height: '100%',
                            textAlign: { xs: 'center', lg: 'left' }
                        }}
                    >
                        {/* Text Content */}
                        <Box sx={{ width: { xs: '100%', lg: '45%' } }}>
                            {/* Social Icons - Mobile */}
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="center"
                                sx={{
                                    display: { xs: 'flex', lg: 'none' },
                                    mb: 2,
                                    mt:8
                                }}
                            >
                                {socialIcons.map((social) => (
                                    <IconButton
                                        key={social.name}
                                        href={social.link}
                                        aria-label={social.name}
                                        sx={{
                                            color: '#666',
                                            '&:hover': {
                                                color: primaryColor
                                            }
                                        }}
                                    >
                                        {social.icon}
                                    </IconButton>
                                ))}
                            </Stack>

                            <Typography
                                variant="h6"
                                color={primaryColor}
                                sx={{ mb: 2 }}
                            >
                                Hello I'm
                            </Typography>

                            <Typography
                                variant="h2"
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#333',
                                    mb: 2,
                                    fontSize: { xs: '2.5rem', md: '3.75rem' }
                                }}
                            >
                                {name}
                            </Typography>

                            <Typography
                                variant="h5"
                                sx={{
                                    color: '#666',
                                    mb: 3,
                                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                                }}
                            >
                                {title}
                            </Typography>

                            <Typography
                                sx={{
                                    color: '#666',
                                    mb: 4,
                                    maxWidth: { xs: '100%', lg: '80%' }
                                }}
                            >
                                {description}
                            </Typography>

                            <Button
                                variant="contained"
                                onClick={()=> handleItemClick("Pricing")}
                                sx={{
                                    bgcolor: primaryColor,
                                    color: 'white',
                                    px: 4,
                                    py: 1.5,
                                    textTransform: 'none',
                                    '&:hover': {
                                        bgcolor: primaryColor
                                    }
                                }}
                            >
                                Hire Me
                            </Button>
                        </Box>

                        {/* Image */}
                        <Box
                            sx={{
                                width: { xs: '100%', lg: '45%' },
                                height: { xs: 'auto', lg: '500px' }
                            }}
                        >
                            <img
                                src={require('../content/yassine1.png')}
                                alt="Professional portrait"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px'
                                }}
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default Hero;