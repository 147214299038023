import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Portfolio from "./components/Portfolio";
import Pricing from "./components/Pricing";
import Technologies from "./components/Technologies";
import { clientsData } from "./content/mockData";

export default function MyApp() {
  return (
    <div style={{ flex: 1, height: '100vh' }}>
      <Header />
      <Hero />
      <About />
      <Portfolio clients={clientsData}/>
      <Technologies/>
      <Pricing />
      <Contact />
    </div>
  );
}
