import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import useScrollDirection from '../hooks/useScrollDirections';
import { primaryColor } from '../content/constants';


const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isVisible = useScrollDirection();
  const [activeItem, setActiveItem] = useState('Home');
  const navItems = ['Home', 'About', 'Services', 'Portfolio', 'Technologies', 'Pricing', 'Contact'];

  const handleMenu = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Nouvelle fonction pour gérer le clic sur un élément
  const handleItemClick = (item: string) => {
    setActiveItem(item);
    handleClose();
    
    // Scroll vers la section
    const element = document.getElementById(item);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ 
        background: 'white', 
        boxShadow: 'none',
        padding: '0.5rem 2rem',
        transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
        transition: 'transform 0.3s ease',
          '& .MuiToolbar-root': {
            padding: 0
          }
      }}>
        <Toolbar sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0
        }}>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              color: '#333',
              fontWeight: 'bold',
            }}
          >
            <span style={{ color: primaryColor }}>Build</span>
            lancer
          </Typography>
          
          {/* Desktop menu */}
          <Box sx={{ 
            display: { xs: 'none', md: 'flex' },
            gap: '2rem'
          }}>
            {navItems.map((item) => (
              <Button 
                key={item}
                onClick={() => handleItemClick(item)}
                sx={{ 
                  color: activeItem === item ? primaryColor : '#333',
                  textTransform: 'none',
                  padding: 0,
                  minWidth: 'auto',
                  position: 'relative',
                  '&:hover': {
                    color: primaryColor,
                    background: 'transparent'
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '2px',
                    bottom: '-4px',
                    left: 0,
                    backgroundColor: primaryColor,
                    transform: activeItem === item ? 'scaleX(1)' : 'scaleX(0)',
                    transition: 'transform 0.3s ease-in-out'
                  }
                }}
              >
                {item}
              </Button>
            ))}
          </Box>

          {/* Mobile menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ color: '#333', padding: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {navItems.map((item) => (
                <MenuItem 
                  key={item} 
                  onClick={() => handleItemClick(item)}
                  sx={{
                    color: activeItem === item ? primaryColor : '#333',
                    '&:hover': {
                      color: primaryColor,
                      backgroundColor: 'rgba(76, 175, 149, 0.04)'
                    }
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;