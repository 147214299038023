// hooks/useScrollDirection.ts
import { useState, useEffect } from 'react';

const useScrollDirection = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      const currentScrollY = window.scrollY;
      
      // Si on scrolle vers le bas et qu'on n'est pas tout en haut
      if (currentScrollY > lastScrollY && currentScrollY > 80) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', controlNavbar);
    
    // Nettoyage
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]); // Dépendance au dernier scroll

  return isVisible;
};

export default useScrollDirection;