import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Link } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useRef } from 'react';
import { primaryColor } from '../content/constants';


interface Client {
    id: number;
    name: string;
    logo: string;
    websitePreview: string;
    description: string;
    projectLink: string;
}

interface PortfolioProps {
    clients: Client[];
}

const ClientCard: React.FC<{ client: Client }> = ({ client }) => (
    <Card
        sx={{
            minWidth: 300,
            maxWidth: 300,
            margin: '0 15px',
            height: 450,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'transform 0.3s ease',
            '&:hover': {
                transform: 'translateY(-5px)',
            }
        }}
    >
        {/* Logo du client */}
        <CardMedia
            component="img"
            height="60"
            image={client.logo}
            alt={`${client.name} logo`}
            sx={{
                maxWidth: 300,
                objectFit: 'contain',
                paddingTop: 3,
                paddingBottom: 3,
                justifySelf: 'center',
                bgcolor: '#f5f5f5'
            }}
        />

        {/* Aperçu du site */}
        <CardMedia
            component="img"
            height="140"
            image={client.websitePreview}
            alt={`${client.name} website preview`}
            sx={{ objectFit: 'cover' }}
        />

        <CardContent sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1
        }}>
            <Typography variant="h6" component="h3" gutterBottom>
                {client.name}
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1 }}>
                {client.description}
            </Typography>

            <Link
                href={client.projectLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    color: primaryColor,
                    textDecoration: 'none',
                    fontWeight: 500,
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                }}
            >
                View the project →
            </Link>
        </CardContent>
    </Card>
);

const Portfolio: React.FC<PortfolioProps> = ({ clients }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const scroll = (direction: 'left' | 'right') => {
        if (containerRef.current) {
            const scrollAmount = 330; // Card width + margin
            const currentScroll = containerRef.current.scrollLeft;
            containerRef.current.scrollTo({
                left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Box
            id="Portfolio"
            sx={{
                py: 8,
                bgcolor: '#f8f9fa',
                position: 'relative'
            }}
        >
            <Typography
                variant="h4"
                component="h2"
                align="center"
                sx={{
                    mb: 6,
                    fontWeight: 'bold',
                    color: '#333',
                    '& span': {
                        color: primaryColor
                    }
                }}
            >
                Happiest <span>Clients</span>
            </Typography>

            {/* Navigation Arrows */}
            <IconButton
                onClick={() => scroll('left')}
                sx={{
                    position: 'absolute',
                    left: 20,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'white',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    '&:hover': {
                        bgcolor: '#f5f5f5'
                    },
                    zIndex: 2
                }}
            >
                <ArrowBackIosIcon />
            </IconButton>

            <IconButton
                onClick={() => scroll('right')}
                sx={{
                    position: 'absolute',
                    right: 20,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'white',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    '&:hover': {
                        bgcolor: '#f5f5f5'
                    },
                    zIndex: 2
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>

            {/* Carousel Container */}
            <Box
                ref={containerRef}
                sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    padding: 0,
                    scrollbarWidth: 'none', // Firefox
                    '&::-webkit-scrollbar': { display: 'none' }, // Chrome
                    px: 8,
                    '-ms-overflow-style': 'none', // IE/Edge
                    scrollBehavior: 'smooth'
                }}
            >
                {clients.map((client, index) => (
                    <ClientCard key={client.id} client={client} />
                ))}
            </Box>
        </Box>
    );
};

export default Portfolio;