import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { primaryColor } from '../content/constants';


interface FormData {
  projectType: string;
  missionType: string;
  budget: string;
  email: string;
  phone: string;
  countryCode: string;
  firstName: string,
  lastName: string,
  description: string,
}

const PROJECT_TYPES = [
  {
    value: 'web_app',
    label: 'Web Application',
    description: 'Complete web application with back-office and advanced features'
  },
  {
    value: 'mobile_app',
    label: 'Mobile Application',
    description: 'Native iOS/Android application or hybrid application'
  },
  {
    value: 'website',
    label: 'Showcase Website',
    description: 'Professional website to present your business'
  },
  {
    value: 'ecommerce',
    label: 'E-commerce Website',
    description: 'Complete online store with product management and payments'
  },
  {
    value: 'automation',
    label: 'Automation & Integration',
    description: 'Process automation, system integration, scripts'
  },
  {
    value: 'api_development',
    label: 'API Development',
    description: 'Creation of REST/GraphQL APIs and web services'
  }
];

const MISSION_TYPES = [
  {
    value: 'long_term',
    label: 'Long-term Mission',
    description: '6 months or more'
  },
  {
    value: 'medium_term',
    label: 'Medium-term Mission',
    description: '3 to 6 months'
  },
  {
    value: 'short_term',
    label: 'Short-term Mission',
    description: '1 to 3 months'
  },
  {
    value: 'one_time',
    label: 'One-time Project',
    description: 'Less than a month'
  }
];

const BUDGET_RANGES = [
  {
    value: 'Less than $1,000',
    label: 'Less than $1,000',
    description: 'Simple and one-time projects'
  },
  {
    value: '$1,000 - $5,000',
    label: '$1,000 - $5,000',
    description: 'Medium-sized projects'
  },
  {
    value: '$5,000 - $20,000',
    label: '$5,000 - $20,000',
    description: 'Complex projects'
  },
  {
    value: '$100,000 - $200,000',
    label: '$100,000 - $200,000',
    description: 'Large corporate projects'
  },
  {
    value: 'More than $200,000',
    label: 'More than $200,000',
    description: 'Extensive projects and complete solutions'
  }
];

const COUNTRY_CODES = [
  { code: 'FR', label: 'France (+33)' },
  { code: 'MA', label: 'Morocco (+212)' },
  { code: 'BE', label: 'Belgium (+32)' },
  { code: 'CH', label: 'Switzerland (+41)' },
  { code: 'US', label: 'United States (+1)' },
  { code: 'CA', label: 'Canada (+1)' },
  { code: 'DE', label: 'Germany (+49)' },
  { code: 'IT', label: 'Italy (+39)' },
  { code: 'ES', label: 'Spain (+34)' },
  { code: 'GB', label: 'United Kingdom (+44)' },
  { code: 'AU', label: 'Australia (+61)' },
  { code: 'JP', label: 'Japan (+81)' },
  { code: 'IN', label: 'India (+91)' },
  { code: 'CN', label: 'China (+86)' },
  { code: 'RU', label: 'Russia (+7)' },
  { code: 'BR', label: 'Brazil (+55)' },
  { code: 'ZA', label: 'South Africa (+27)' },
  { code: 'AE', label: 'United Arab Emirates (+971)' },
  { code: 'NG', label: 'Nigeria (+234)' },
  { code: 'AR', label: 'Argentina (+54)' },
  { code: 'MX', label: 'Mexico (+52)' },
];


const Pricing = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    projectType: '',
    missionType: '',
    budget: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    description: '',
    countryCode: 'EN'
  });

  const handleNext = () => {
    if (activeStep === 0 && !formData.projectType) {
      alert('Please select a project type.');
      return;
    }
    if (activeStep === 1 && !formData.missionType) {
      alert('Please select a mission type.');
      return;
    }
    if (activeStep === 2 && !formData.budget) {
      alert('Please select a budget range.');
      return;
    }
    if (
      activeStep === 3 &&
      (!formData.email || !formData.phone || !formData.countryCode || !formData.firstName || !formData.lastName)
    ) {
      alert('Please fill in all required fields.');
      return;
    }

    if (activeStep === 3) {
      submitForm();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };



  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleChange = (field: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const submitForm = async () => {
    try {
      const response = await fetch('https://api.brevo.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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'
        },
        body: JSON.stringify({
          sender: { email: 'yassinehjiej.dev@gmail.com', name: `${formData.email}` },
          to: [{ email: 'yassinehjiej.dev@gmail.com', name: 'Recipient Name' }],
          subject: 'Nouvelle demande de devis',
          htmlContent: `
            <h1>Nouvelle demande de devis</h1>
            <p>Type de projet : ${formData.projectType}</p>
            <p>Type de mission : ${formData.missionType}</p>
            <p>First Name: ${formData.firstName}</p>
            <p>Last Name: ${formData.lastName}</p>
            <p>Description: ${formData.description}</p>
            <p>Budget : ${formData.budget}</p>
            <p>Email : ${formData.email}</p>
            <p>Téléphone : ${formData.countryCode} ${formData.phone}</p>
          `
        })
      });

      const responseToVisitor = await fetch('https://api.brevo.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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'
        },
        body: JSON.stringify({
          sender: { email: 'yassinehjiej.dev@gmail.com', name: `${formData.email}` },
          to: [{ email: `${formData.email}`, name: 'Recipient Name' }],
          subject: 'Nouvelle demande de devis',
          htmlContent: `
          <h1>Thank You!</h1>
          <p>Dear ${formData.firstName},</p>
          <p>Thank you for your interest in our services. No matter the project you wish to accomplish, you have made the best choice by contacting us. Rest assured, we will reach out to you promptly to discuss your needs.</p>
          <p>We look forward to working with you!</p>
          <br />
          <p>Best regards,</p>
        `
        })
      });

      if (response.ok && responseToVisitor.ok) {
        setActiveStep(4);
      } else {
        alert('Network or server error. Please try again or contact me on yassinehjiej.dev@gmail.com');
      }
    } catch (error) {
      alert('Network or server error. Please try again or contact me on yassinehjiej.dev@gmail.com');
    }
  };


  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        // Project Type Step
        return (
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: 2 }}>
            {PROJECT_TYPES.map((type) => (
              <Card
                key={type.value}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  border: formData.projectType === type.value ? `2px solid ${primaryColor}` : '1px solid #eee',
                  '&:hover': { borderColor: primaryColor }
                }}
                onClick={() => setFormData({ ...formData, projectType: type.value })}
              >
                <FormControlLabel
                  value={type.value}
                  control={<Radio checked={formData.projectType === type.value} />}
                  label={
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {type.label}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {type.description}
                      </Typography>
                    </Box>
                  }
                  sx={{ pointerEvents: 'none' }} // Prevent click from propagating to Radio
                />
              </Card>
            ))}
          </Box>
        );
  
      case 1:
        // Mission Type Step
        return (
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: 2 }}>
            {MISSION_TYPES.map((type) => (
              <Card
                key={type.value}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  border: formData.missionType === type.value ? `2px solid ${primaryColor}` : '1px solid #eee',
                  '&:hover': { borderColor: primaryColor }
                }}
                onClick={() => setFormData({ ...formData, missionType: type.value })}
              >
                <FormControlLabel
                  value={type.value}
                  control={<Radio checked={formData.missionType === type.value} />}
                  label={
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {type.label}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {type.description}
                      </Typography>
                    </Box>
                  }
                  sx={{ pointerEvents: 'none' }} // Prevent click from propagating to Radio
                />
              </Card>
            ))}
          </Box>
        );
  
      case 2:
        // Budget Step
        return (
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: 2 }}>
            {BUDGET_RANGES.map((range) => (
              <Card
                key={range.value}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  border: formData.budget === range.value ? `2px solid ${primaryColor}` : '1px solid #eee',
                  '&:hover': { borderColor: primaryColor }
                }}
                onClick={() => setFormData({ ...formData, budget: range.value })}
              >
                <FormControlLabel
                  value={range.value}
                  control={<Radio checked={formData.budget === range.value} />}
                  label={
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {range.label}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {range.description}
                      </Typography>
                    </Box>
                  }
                  sx={{ pointerEvents: 'none' }} // Prevent click from propagating to Radio
                />
              </Card>
            ))}
          </Box>
        );

      case 3:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              fullWidth
              label="First Name"
              required
              value={formData.firstName || ''}
              onChange={handleChange('firstName')}
            />
            <TextField
              fullWidth
              label="Last Name"
              required
              value={formData.lastName || ''}
              onChange={handleChange('lastName')}
            />
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={4}
              value={formData.description || ''}
              onChange={handleChange('description')}
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              required
              value={formData.email}
              onChange={handleChange('email')}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ width: 200 }}>
                <InputLabel>Code Pays</InputLabel>
                <Select
                  value={formData.countryCode}
                  onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
                  label="Country code"
                >
                  {COUNTRY_CODES.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                required
                label="Phone number"
                value={formData.phone}
                onChange={handleChange('phone')}
              />
            </Box>
          </Box>
        );

        case 4:
          return (
            <Box sx={{ textAlign: 'center', py: 6 }}>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Thank You, {formData.firstName}!
              </Typography>
              <Typography variant="body1" gutterBottom>
                Thank you for your interest in our services. No matter the project you wish to accomplish, you have made the best choice by contacting us.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Rest assured, we will reach out to you promptly to discuss your needs.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 4, bgcolor: primaryColor, '&:hover': { bgcolor: primaryColor } }}
                onClick={() => setActiveStep(0)} // Reset to the first step
              >
                Modify My Request
              </Button>
            </Box>
          );
      default:
        return null;
    }
  };

  return (
    <Box id="Pricing" sx={{ py: 8, backgroundColor: '#f8f9fa' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          align="center"
          sx={{ mb: 6, fontWeight: 'bold', color: '#333' }}
        >
          <span style={{ color: primaryColor }}>Pricing</span>
        </Typography>

        <Card sx={{ p: 4 }}>
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            <Step>
              <StepLabel>Project Type</StepLabel>
            </Step>
            <Step>
              <StepLabel>Mission Type</StepLabel>
            </Step>
            <Step>
              <StepLabel>Budget</StepLabel>
            </Step>
            <Step>
              <StepLabel>Contact</StepLabel>
            </Step>
          </Stepper>

          {renderStepContent(activeStep)}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          {activeStep !== 4 && <><Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                bgcolor: primaryColor,
                '&:hover': {
                  bgcolor: primaryColor
                }
              }}
            >
              {activeStep === 3 ? 'Submit' : 'Next'}
            </Button></>}
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default Pricing;