import React from "react";
import { Box, Typography, Link, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Email, Phone } from "@mui/icons-material";

const Contact: React.FC = () => {
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box
      id="Contact"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
        backgroundColor: "#f5ebe1", // Match your website's theme color
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: "600px",
        margin: "2rem auto",
        marginBottom: "100px",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: "1rem",
          fontWeight: "bold",
          color: "#2e2e2e", // Adjusted to match your theme
        }}
      >
        Contact Me
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography
          variant="body1"
          sx={{ marginRight: "0.5rem", fontSize: "1rem", color: "#4d4d4d" }}
        >
          <Email/>
        </Typography>
        <Link
          href="mailto:yassinehjiej.dev@gmail.com"
          sx={{
            color: "#b08968", // Match website theme
            fontSize: "1rem",
            textDecoration: "none",
            marginRight: "0.5rem",
          }}
        >
          yassinehjiej.dev@gmail.com
        </Link>
        <Tooltip title="Copy Email">
          <IconButton
            onClick={() => handleCopy("yassinehjiej.dev@gmail.com")}
            sx={{ color: "#6b705c" }} // Adjust color to match theme
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "2rem", // Added extra space here
        }}
      >
        <Typography
          variant="body1"
          sx={{ marginRight: "0.5rem", fontSize: "1rem", color: "#4d4d4d" }}
        >
          <Phone/>
        </Typography>
        <Link
          href="tel:+212643545792"
          sx={{
            color: "#b08968", // Match website theme
            fontSize: "1rem",
            textDecoration: "none",
            marginRight: "0.5rem",
          }}
        >
          +212643545792
        </Link>
        <Tooltip title="Copy Phone">
          <IconButton
            onClick={() => handleCopy("+212643545792")}
            sx={{ color: "#6b705c" }} // Adjust color to match theme
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: "#6b705c",
          fontSize: "0.875rem",
          marginTop: "2rem", // Extra spacing from the bottom
          paddingBottom: "1rem", // Space below the footer text
        }}
      >
        All rights reserved 2024
      </Typography>
    </Box>
  );
};

export default Contact;
