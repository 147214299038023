import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { primaryColor } from '../content/constants';

interface HeroProps {
    name?: string;
    title?: string;
    description?: string;
}

const About: React.FC<HeroProps> = ({
    name = "Why You Hire Me?",
    description = "With several years in the software development field, I’ve worked across diverse industries, bringing stability to chaotic codebases and efficiency to complex systems. Whether you’re looking for a rock-solid foundation or a last-minute rescue, I’m the developer you want in your corner."
}) => {

    const handleItemClick = (item: string) => {
        // Scroll vers la section
        const element = document.getElementById(item);
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      };

    return (
        <Box id="About">
            <Box
                sx={{
                    height: { xs: 'auto', md: '700px' }, // Hauteur fixe sur desktop
                    minHeight: { xs: '100vh', md: '700px' },
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 0
                    }
                }}
            >

                <Container
                    maxWidth="lg"
                    sx={{
                        height: '100%',
                        py: { xs: 4, md: 0 },
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: { xs: 4, lg: 8 },
                            height: '100%',
                            textAlign: { xs: 'center', lg: 'left' }
                        }}
                    >
                        {/* Image */}
                        <Box
                            sx={{
                                width: { xs: '100%', lg: '45%' },
                                height: { xs: 'auto', lg: '500px' }
                            }}
                        >
                            <img
                                src={require('../content/yassine10.png')}
                                alt="Professional portrait"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px'
                                }}
                            />
                        </Box>
                        {/* Text Content */}
                        <Box sx={{ width: { xs: '100%', lg: '45%' } }}>
                            <Typography
                                variant="h6"
                                color={primaryColor}
                                sx={{ mb: 2 }}
                            >
                                About
                            </Typography>

                            <Typography
                                variant="h2"
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#333',
                                    mb: 2,
                                    fontSize: { xs: '2.5rem', md: '3.75rem' }
                                }}
                            >
                                {name}
                            </Typography>

                            <Typography
                                sx={{
                                    color: '#666',
                                    mb: 4,
                                    maxWidth: { xs: '100%', lg: '80%' }
                                }}
                            >
                                {description}
                            </Typography>

                            <Button
                                variant="contained"
                                onClick={()=>handleItemClick('Portfolio')}
                                sx={{
                                    bgcolor: primaryColor,
                                    color: 'white',
                                    px: 4,
                                    py: 1.5,
                                    textTransform: 'none',
                                    '&:hover': {
                                        bgcolor: primaryColor
                                    }
                                }}
                            >
                                My happiest clients
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default About;